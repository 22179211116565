exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-passwort-request-js": () => import("./../../../src/pages/passwort/request.js" /* webpackChunkName: "component---src-pages-passwort-request-js" */),
  "component---src-pages-passwort-reset-js": () => import("./../../../src/pages/passwort/reset.js" /* webpackChunkName: "component---src-pages-passwort-reset-js" */),
  "component---src-pages-registrieren-index-js": () => import("./../../../src/pages/registrieren/index.js" /* webpackChunkName: "component---src-pages-registrieren-index-js" */),
  "component---src-templates-chronic-index-js": () => import("./../../../src/templates/chronic/index.js" /* webpackChunkName: "component---src-templates-chronic-index-js" */),
  "component---src-templates-judge-index-js": () => import("./../../../src/templates/judge/index.js" /* webpackChunkName: "component---src-templates-judge-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-posts-index-js": () => import("./../../../src/templates/posts/index.js" /* webpackChunkName: "component---src-templates-posts-index-js" */)
}

