import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';

const token = typeof window !== "undefined" ? window.localStorage.getItem("userAuthenticationToken") : null;

export const client = new ApolloClient({
  uri: process.env.GATEKEEPER_GRAPHQL_API_ENDPOINT,
  fetch,
  request: operation => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`
      },
    });
  }
})
