/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import smoothscroll from 'smoothscroll-polyfill';


const onClientEntry = () => {
  smoothscroll.polyfill();
}
export { onClientEntry };

export { wrapRootElement } from './src/apollo/wrap-root-element';
